var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questions-status-report-tables"},_vm._l((_vm.reportData),function(table,tableIndex){return _c('div',{key:tableIndex,staticClass:"mt-8"},[_c('div',{staticClass:"d-flex justify-center mb-5"},[(table.tableScope.parentScopeData.id)?_c('h3',{staticClass:"pb-2 px-1 bordered-b"},[_c('span',{staticClass:"flex-sm-row flex-column justify-center align-center"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("servertranslate")(table.tableScope.parentScopeData.name))+" ")]),_vm._l((table.tableScope.childsData),function(childScope,childScopeIndex){return _c('span',{key:childScopeIndex},[_c('span',{staticClass:"mx-1"},[_vm._v(" - ")]),_vm._v(" "+_vm._s(_vm._f("servertranslate")(childScope.name))+" ")])})],2),(_vm.fromDate || _vm.toDate)?_c('span',{staticClass:"d-flex justify-center mt-2"},[(_vm.fromDate)?_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.$t('labels.fromDate'))+" "+_vm._s(_vm.fromDate)+" ")]):_vm._e(),(_vm.toDate)?_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.$t('labels.toDate'))+" "+_vm._s(_vm.toDate)+" ")]):_vm._e()]):_vm._e()]):_vm._e()]),_c('v-data-table',{staticClass:" qb-table",attrs:{"items":table.tableData,"headers":_vm.headers,"hide-default-footer":"","mobile-breakpoint":0,"no-data-text":_vm.$t('labels.noData'),"items-per-page":table.tableData.length},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(table.tableData.indexOf(item) + 1)+" ")]}},{key:"item.courseName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("servertranslate")(item.courseName))+" ")]}},{key:"item.studyPlanName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("servertranslate")(item.studyPlanName))+" ")]}},{key:"item.incomplete",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionsStatusData[_vm.questionsStatusEnum.INCOMPLETE].questionsCount)+" ")]}},{key:"item.pendingReview",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionsStatusData[_vm.questionsStatusEnum.PENDING_REVIEW].questionsCount)+" ")]}},{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionsStatusData[_vm.questionsStatusEnum.COMPLETE].questionsCount)+" ")]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionsStatusData[_vm.questionsStatusEnum.APPROVED].questionsCount)+" ")]}},{key:"item.rejected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionsStatusData[_vm.questionsStatusEnum.REJECTED].questionsCount)+" ")]}},{key:"item.needToFixTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionsStatusData.needToFixTime.questionsCount)+" ")]}},{key:"item.needToFixDifficulty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionsStatusData.needToFixDifficulty.questionsCount)+" ")]}},{key:"item.totalQuestionsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalQuestionsCount)+" ")]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"grey lighten-2"},[_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t('tableHeaders.questionsCount'))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.questionsStatusTotalsData[ _vm.questionsStatusEnum.INCOMPLETE ].questionsCount)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.questionsStatusTotalsData[ _vm.questionsStatusEnum.PENDING_REVIEW ].questionsCount)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.questionsStatusTotalsData[ _vm.questionsStatusEnum.COMPLETE ].questionsCount)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.questionsStatusTotalsData[ _vm.questionsStatusEnum.APPROVED ].questionsCount)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.questionsStatusTotalsData[ _vm.questionsStatusEnum.REJECTED ].questionsCount)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.totalNeedToFixTime)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.totalNeedToFixDifficulty)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(table.tableTotals.totalQuestionsCount)+" ")])])]},proxy:true}],null,true)}),(tableIndex !== (_vm.reportData.length - 1))?_c('v-divider',{staticClass:"mt-8 mx-6"}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }