



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { QuestionsStatusReportFilters } from '@/types/reports.type';
import { QUESTIONS_STATUS_REPORT } from '../../enums';

@Component
export default class QuestionsStatusReportFiltersComponent extends Vue {
  @Prop(Object) readonly filtersInputs!: QuestionsStatusReportFilters;

  displayDateFrom = false;

  displayDateTo = false;

  currentDate = new Date().toISOString();

  get filtersData() {
    return {
      coursesStatus: [
        {
          id: QUESTIONS_STATUS_REPORT.COURSE_STATUS.ALL,
          name: this.$t('statusReportCourseStatus.allCourses'),
        },
        {
          id: QUESTIONS_STATUS_REPORT.COURSE_STATUS.HAS_QUESTION,
          name: this.$t('statusReportCourseStatus.hasQuestions'),
        },
        {
          id: QUESTIONS_STATUS_REPORT.COURSE_STATUS.HAS_NO_QUESTION,
          name: this.$t('statusReportCourseStatus.hasNoQuestions'),
        },
      ],

      tablesDistributions: this.customizedTablesDistributions,
    };
  }

  get customizedTablesDistributions() {
    const tablesDistributions = [
      {
        id: QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_FACULTY,
        name: this.$t('statusReportDistributions.byFaculty'),
      },
      {
        id: QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_DEPARTMENT,
        name: this.$t('statusReportDistributions.byDepartment'),
      },
      {
        id: QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_GRADE,
        name: this.$t('statusReportDistributions.byGrade'),
      },
    ];

    const scopeLevel = this.filtersInputs.scopeId?.split('.').length || 0;
    if (scopeLevel < 4) {
      this.filtersInputs.tablesDist = QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_FACULTY;
      return tablesDistributions;
    }
    if (scopeLevel < 5) {
      this.filtersInputs.tablesDist = QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_GRADE;
      return tablesDistributions.slice(2);
    }
    this.filtersInputs.tablesDist = QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_GRADE;
    return [];
  }

  get currentLocale() {
    return this.$store.state.lang.lang;
  }

  get formattedDateFrom() {
    return this.formatDate(this.filtersInputs.fromDate);
  }

  set formattedDateFrom(val) {
    this.filtersInputs.fromDate = this.formatDate(val, true);
  }

  get formattedDateTo() {
    return this.formatDate(this.filtersInputs.toDate);
  }

  set formattedDateTo(val) {
    this.filtersInputs.toDate = this.formatDate(val, true);
  }

  formatDate(date, reverse = false) {
    if (!date) return '';

    if (reverse) {
      const [year, month, day] = date.split('/');
      return `${year}-${month}-${day}`;
    }
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }
}
