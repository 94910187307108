






































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableHeader } from '@/types/general.type';
import { QuestionsStatusStatsReportTable } from '@/types/reports.type';
import { QUESTIONS_STATUS } from '../../enums';

@Component
export default class QuestionsStatusReportTablesComponent extends Vue {
  @Prop(Array) readonly reportData!: QuestionsStatusStatsReportTable[];

  questionsStatusEnum = QUESTIONS_STATUS;

  headerClass = 'grey darken-4 secondary--text pa-3 qb-table__header';

  get fromDate(): string {
    return this.$route.query.fromDate as string || '';
  }

  get toDate(): string {
    return this.$route.query.toDate as string || '';
  }

  get headers(): TableHeader[] {
    return [
      {
        text: this.$t('tableHeaders.index'),
        align: 'center',
        value: 'index',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.courseName'),
        align: 'center',
        value: 'courseName',
        sortable: false,
        class: this.headerClass,

      },
      {
        text: this.$t('tableHeaders.studyPlan'),
        value: 'studyPlanName',
        align: 'center',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.incomplete'),
        align: 'center',
        value: 'incomplete',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.pendingReview'),
        align: 'center',
        value: 'pendingReview',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.complete'),
        align: 'center',
        value: 'complete',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.approved'),
        align: 'center',
        value: 'approved',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.rejected'),
        align: 'center',
        value: 'rejected',
        sortable: false,
        class: this.headerClass,
      },
      // {
      //   text: this.$t('tableHeaders.deleted'),
      //   align: 'center',
      //   value: 'deleted',
      //   sortable: false,
      //   class: this.headerClass,
      // },
      {
        text: `${this.$t('labels.NEED_TO_FIX')} ${this.$t('labels.TIME')}`,
        align: 'center',
        value: 'needToFixTime',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: `${this.$t('labels.NEED_TO_FIX')} ${this.$t('labels.difficultyLevel')}`,
        align: 'center',
        value: 'needToFixDifficulty',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionsCount'),
        align: 'center',
        value: 'totalQuestionsCount',
        sortable: false,
        class: this.headerClass,
      },
    ];
  }
}
