











































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  QuestionsStatusReportQuery,
  QuestionsStatusReportFilters,
  QuestionsStatusStatsReportTable,
  QuestionsStatusStatsReportResponse,
} from '@/types/reports.type';
import { ScopeClass, Lang } from '@/types/general.type';
import ReportsProvider from '@/providers/reports.provider';
import ScopeComponent from '@/components/scope.component.vue';
import UTILS from '@/shared/utils';
import {
  APPIDS,
  QUESTIONS_STATUS_REPORT,
} from '../../enums';
import QuestionsStatusReportTablesComponent from './QuestionsStatusReportTables.component.vue';
import
QuestionsStatusReportFiltersComponent
  from '../filters/QuestionsStatusReportFilters.component.vue';
import
ExportQuestionsStatusReportXLSXMixin
  from '../../mixins/exportQuestionsStatusReportXLSX.mixin';

import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    ScopeComponent,
    QuestionsStatusReportTablesComponent,
    QuestionsStatusReportFiltersComponent,
  },
})
export default class QuestionsStatusStatsReport extends mixins(
  Toaster,
  ExportQuestionsStatusReportXLSXMixin,
) {
  appId: string = APPIDS.QUESTIONS_STATUS_REPORT;

  loading = false;

  showReport = false;

  reportData: QuestionsStatusStatsReportTable[] = [];

  currentLang = this.$store.state.lang.lang;

  scopeComponentRef: ScopeClass = ScopeComponent;

  filtersInputs: QuestionsStatusReportFilters = {
    scopeId: this.$store.state.scope.selectedScopeId,
    courseStatus: QUESTIONS_STATUS_REPORT.COURSE_STATUS.ALL,
    tablesDist: QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_FACULTY,
    fromDate: '',
    toDate: '',
  };

  get currentFromDate(): string {
    return this.$route.query.fromDate as string || '';
  }

  get currentToDate(): string {
    return this.$route.query.toDate as string || '';
  }

  mounted() {
    this.fetchFiltersData();
  }

  @Watch('$store.state.lang.lang')
  onLangChange(newVal) {
    this.currentLang = newVal;
    if (this.showReport) {
      this.search();
    }
  }

  fetchFiltersData() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (this.$route.query[key]) {
        this.filtersInputs[key] = this.$route.query[key];
      }
    });
  }

  scopeChange(selectedScopeId) {
    this.filtersInputs.scopeId = selectedScopeId;
  }

  search() {
    if (!this.filtersInputs.scopeId || (this.filtersInputs.scopeId.split('.').length < 3)) {
      this.$store.commit('openScope');
      this.scopeComponentRef.setFocus();
      return;
    }
    this.getReportData();
  }

  resetSearch() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (key === 'courseStatus') {
        this.filtersInputs[key] = QUESTIONS_STATUS_REPORT.COURSE_STATUS.ALL;
      } else if (key === 'tablesDist') {
        this.filtersInputs[key] = QUESTIONS_STATUS_REPORT.DISTRIBUTION.BY_FACULTY;
      } else { this.filtersInputs[key] = ''; }
    });
    this.resetScope();
    this.showReport = false;
    this.reportData = [];
  }

  resetScope() {
    this.$store.commit('resetScope');
  }

  async getReportData() {
    try {
      const reqQuery: QuestionsStatusReportQuery = {
        ...this.filtersInputs,
        fromDate: UTILS.CONVERT_FROM_LOCAL_DATE_TO_ISO_STRING_DATE(this.filtersInputs.fromDate),
        toDate: UTILS.CONVERT_FROM_LOCAL_DATE_TO_ISO_STRING_DATE(this.filtersInputs.toDate, true),
      };
      if (this.currentLang === 'en') { reqQuery.langIsEn = true; }

      this.loading = true;
      const {
        reportData,
      }: QuestionsStatusStatsReportResponse = await ReportsProvider.getQuestionsStatusStatsReport(
        this.appId, reqQuery,
      );
      this.reportData = reportData;
      this.$router.replace({ query: { ...this.filtersInputs } }).catch(() => { console.log(); });
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    } finally {
      this.showReport = true;
    }
  }

  printReport() {
    const printElmHtml = document.querySelector('#statusReportTables')?.innerHTML;
    UTILS.PRINT_REPORT(printElmHtml, this.$store.state.lang.lang);
  }

  exportReport() {
    this.exportXLSX(this.reportData, this.currentFromDate, this.currentToDate);
  }
}
